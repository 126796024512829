/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

import { tns } from 'tiny-slider';
import "@lottiefiles/lottie-player";

document.querySelectorAll('.text-images-block').forEach(block => {
    const slider = block.querySelector(".image-slider")
    const controlsContainer = block.querySelector('.slider-controls');
    console.log("Slider: ", slider);

    const tinySlider = tns({
        container: slider,
        items: 1,
        controls: true,
        controlsContainer: controlsContainer,
        nav: false,
        navPosition: 'bottom',
        autoplay: true,
        autoplayTimeout: 3000,
        loop: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        touch: true,
        edgePadding: -2,
    })
})


document.querySelectorAll('.logo-showcase-block').forEach(block => {
    block.stillInViewport = false;
    var slider = block.querySelector('.logo-slider');
    var controlsContainer = block.querySelector('.slider-controls');

    // add lottie animation to slider-controls
    const lottie = block.getElementsByTagName("lottie-player")[0];
    lottie.count = 1;

    lottie.addEventListener("ready", _event => {
        logoLottiePlay(block);
    }) 


    

    const tinySlider = tns({
        container: slider,
        items: 2,
        controls: true,
        controlsContainer: controlsContainer,
        nav: false,
        navPosition: 'bottom',
        autoplay: true,
        autoplayTimeout: 3000,
        loop: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        touch: true,
        edgePadding: 0,
        responsive: {
            501: {
                items: 3,
            },
            800: {
                items: 4,
            }



        }
    })

    // tinySlider.events.on('indexChanged', (ev, ev2) => console.log("indexChanged", ev.items, ev2));
    // tinySlider.events.on('newBreakpointStart', (ev, ev2) => console.log("newBreakpointStart", ev.items, ev2));
});

// The element must be smaller then the viewport
function fullInViewport(element, padding_top = 0, padding_bottom = 0) {
    const bounds = element.getBoundingClientRect();
    return ((bounds.top+padding_top) > 0 && (bounds.bottom-padding_bottom) < innerHeight);
}

function logoLottiePlay(block) {
    const padding_top = window.getComputedStyle(block).getPropertyValue('padding-top');
    const in_viewport = fullInViewport(block, parseInt(padding_top));

    const lottie = block.getElementsByTagName("lottie-player")[0];
    // Leaving viewport
    if (block.stillInViewport && in_viewport === false) {
        lottie.stop();
        lottie.seek(55); // We set the frame here to ensure that the first frame is not shown since it is the first line segment.
        block.stillInViewport = false;
    }

    // Update if still in viewport
    if (in_viewport === false) {
        block.stillInViewport = false;
    }

    // Entering viewport
    if (in_viewport && block.stillInViewport === false) {
        lottie.seek(0); // We set the starting frame here since we stopped it at the end
        lottie.play();
        block.stillInViewport = true;
    }

}

document.addEventListener('scroll', event => {
    document.querySelectorAll('.logo-showcase-block').forEach(block => {
        logoLottiePlay(block);
    }); 
});


function navigation_menu_init() {
    const menu_btn = document.getElementById("menu-btn");
    const navbar_container = document.getElementById("navigation-menu-container");

    menu_btn.addEventListener('click', _event => {
        document.body.classList.toggle("menu-open");
    });

    document.addEventListener("click", event => {
        const target = event.target;
            // console.log(target);
        if (document.body.classList.contains("menu-open")) {
            if (target === menu_btn || menu_btn.contains(target)) {
                return;
            }
            

            if (!(target === navbar_container || (navbar_container && navbar_container.contains(target)))) {
                document.body.classList.remove("menu-open");
            } else if (document.body.classList.contains("menu-open")){
                document.body.classList.remove("menu-open");
            }
        }
    });
}

navigation_menu_init();

document.body.classList.add('loaded');
